<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <span v-if="canAdd()">
          <b-popover
            :target="`BTN_ADD_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.add')">
          </b-popover>
          <b-btn :id="`BTN_ADD_${id}`" @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>  
        </span>
        <span v-if="canView()">
          <b-popover
            :target="`BTN_EDIT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.edit')">
          </b-popover>
          <b-btn :disabled="disableEdit" :id="`BTN_EDIT_${id}`" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
        </span>
        <span v-if="canAdd()">
          <b-popover
            :target="`BTN_DUPLICATE_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.duplicate')">
          </b-popover>
          <b-btn :disabled="disableDuplicate" :id="`BTN_DUPLICATE_${id}`" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>
        </span>
        <span  v-if="canDelete()">
          <b-popover
            :target="`BTN_DELETE_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.delete')">
          </b-popover>
          <b-btn :disabled="disableDelete" :id="`BTN_DELETE_${id}`" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
        </span>
        <span>
          <b-popover
            :target="`BTN_IMPORT_DOCUMENT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('department.button.import_document')">
          </b-popover>
          <b-btn :id="`BTN_IMPORT_DOCUMENT_${id}`" @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <span>
          <b-popover
            :target="`BTN_EXPORT_DOCUMENT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('department.button.export_document')">
          </b-popover>
          <b-btn :id="`BTN_EXPORT_DOCUMENT_${id}`" @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('company')" href="#">
                <span class="action-item-label">{{ $t('department.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('department')" href="#">
                <span class="action-item-label">{{ $t('department.coloring.department') }}</span><font-awesome-icon class="active-check" v-if="coloring.department" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham department-grid-height" id="department-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnDefs"
            :context="context"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.path ? params.data.path : params.data.uuId"
            :rowData="rowData"
            groupDefaultExpanded="-1"
            suppressContextMenu=true
            suppressCellFocus
            suppressMultiSort
            :rowSelection="forceSingleSelection? 'single': 'multiple'"
            treeData

            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <DepartmentModal v-if="allowManage && departmentShow" :id="departmentId" :masterCompany="masterCompany" :parentData="selectedParent" :show.sync="departmentShow" @success="modalSuccess" :title="departmentTitle" :companyData="rowData" :queryParent="true"/>

    <CompanyModal v-if="allowManage && companyShow" :id="companyId" :masterCompany="masterCompany" :parentData="selectedParent" :show.sync="companyShow" @success="modalSuccess" :title="companyTitle"/>
    
    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('department.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('department.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('department.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(`${selected.length > 1? 'department.confirmation.delete_plural':'department.confirmation.delete'}`, [`${ hasChildNodes ? $t('department.confirmation.child_nodes'):'' }`]) }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'name', text: $t('company.field.name') }, { value: 'task_path', text: $t('document.path') }, { value: 'tag', text: $t('field.tag') }]" :mode="'DEPARTMENT'" :show="docImportShow"
      :title="$t('department.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import ListFilter from '@/components/ListFilter/ListFilter';
import { strRandom, extractRowsFromData, DepartmentBeforeMount, pruneTree, addTags, invertColor, makeTree, prepareDepartmentTreeRequest } from '@/helpers';
import { departmentService, companyService, viewProfileService, departmentLinkTagService, compositeService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getPermissionDenyProperties } from '@/helpers/permission';
import { columnDefSortFunc } from '@/views/management/script/common';

import { getKeysWithoutRedactedFields } from '@/services/common';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import StaffCountCellRenderer from '@/components/Aggrid/CellRenderer/StaffCount';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

export default {
  name: "DepartmentSelectorModal",
  components: {
    "ag-grid-vue": AgGridVue,
    DepartmentModal: () => import('./DepartmentModal.vue'),
    CompanyModal: () => import('./CompanyModal.vue'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),

    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer, 
    'staffCountCellRenderer': StaffCountCellRenderer,
    'colorCellRenderer': ColorCellRenderer,
    //Overlay
    noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    title: { 
      type: String,
      default: null 
    },
    show: {
      type: Boolean,
      required: true
    },
    exclude: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    },
    forceSingleSelection: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    hideStaffCount: {
      type: Boolean,
      default: false
    },
    company: {
      type: Object,
      default: null
    },
    selectCompany: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      id: `DEPARTMENT_LIST_${strRandom(5)}`,
      permissionName: "DEPARTMENT",
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
      treeData: null,
      getDataPath: null,
      autoGroupColumnDef: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],
      restoreSelection: [],
      selectedParent: null,
      hasChildNodes: false,

      departmentId: null,
      departmentShow: false,
      companyId: null,
      companyShow: false,

      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: "",
      listStaff: false,

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      departmentMap: {},
      
      coloring: {
        company: false,
        department: false
      },

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const self = this;
    const profileKey = 'department_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    DepartmentBeforeMount(this, this, this.$t('department.title'));
    
    this.gridOptions.onColumnVisible = (params) => {
      let fromToolPanel = params.source == "toolPanelUi"
      if (fromToolPanel) {
        let colKey = params.column.colId;
        let columnMenuColumnIndex = params.columnApi
          .getAllGridColumns()
          .findIndex(col => {
            return col === self.lastOpenColumnMenuParams.column;
          });

        params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
      }
      const cols = params.columnApi.getAllGridColumns().map(i => { 
        return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
      const columnState =  params.columnApi.getColumnState();
      //get the actual hide value from columnState
      for (const col of columnState) {
        const found = cols.find(i => i.colId == col.colId)
        if (found) {
          found.hide = col.hide;
        }
      }
      cols.sort(columnDefSortFunc)
      for (const [index,c] of cols.entries()) {
        params.columnApi.moveColumn(c.colId, index);
      }

      const columns = params.columnApi.getAllDisplayedColumns();
      this.settings[profileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    }
    this.gridOptions.postProcessPopup = params => {
      if ((params.type == 'columnMenu')) {
        self.lastOpenColumnMenuParams = params;
      }
    }

    this.gridOptions.onSortChanged = (event) => {
      const columns = event.columnApi.getAllDisplayedColumns();
      this.settings[profileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    }
    this.gridOptions.onDragStopped = (event) => {
      const columns = event.columnApi.getAllDisplayedColumns();
      this.settings[profileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    }
    this.gridOptions.onFirstDataRendered = (event) => {
      if (this.newToProfile != null && this.newToProfile == true) {
        this.newToProfile = null;
        event.api.sizeColumnsToFit();
        this.$nextTick(() => {
          const columns = event.columnApi.getAllDisplayedColumns();
          this.settings[profileKey] = columns.map(c => getColumnDefs(c));
          this.updateViewProfile();
        })
      }
    }
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
  },
  watch: {
    show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.nodeState = {};
        if (this.preselected) {
          this.selected = [{uuId: this.preselected }];
        }
        this.loadViewProfile();
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    masterCompany() {
      if (this.treeData === null) {
        return null;
      }
      
      const master = this.treeData.filter(c => c.type === 'Primary');
      return master.length > 0 ? master[0] : null;
    },
    companyTitle() {
      return this.companyId && this.companyId.indexOf('COMPANY_NEW') == -1? this.$t('company.title_detail'): this.$t('company.title_new');
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    departmentTitle() {
      return this.departmentId && this.departmentId.indexOf('DEPARTMENT_NEW') == -1? this.$t('department.title_detail'): this.$t('department.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title: this.$t('department.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('department.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    processNodes() {
      const self = this;
      this.gridApi.forEachNode((node/**, b */) => {
        if (self.restoreSelection.filter(r => node.id === r.uuId ||
            node.id.endsWith(r.uuId)).length !== 0) {
          node.setSelected(true);
        }
        if (self.company === null &&
            typeof node.data.type !== 'undefined' &&
            node.data.uuId !== this.$store.state.company.uuId) {
          node.setExpanded(false);
        }

        if (this.preselected && this.preselected.includes(node.key)) {
          node.setExpanded(true);
          while (node.parent) {
            node.parent.setExpanded(true);
            node = node.parent;
          }
        }
      });
    },
    onGridReady(/**params**/) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      this.reloadData(false);
      if (this.preselected) {
        this.restoreSelection = this.preselected.split('|').map(p => { return {uuId: p }});
      }
    },
    checkDisableButtons(self=null) {
      const _this = self || this;
      
      _this.disableEdit = _this.disableDuplicate = _this.selected.length != 1;
      _this.disableDelete = this.disableDuplicate = _this.selected.length < 1 || this.selected.filter(d => typeof d.type !== 'undefined').length !== 0;
      _this.disableOk = _this.selected.length < 1;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.departmentId = `DEPARTMENT_NEW_${strRandom(5)}`;
        
        // for a new department the selected node will be the parent of the new department
        if (this.gridApi.getSelectedNodes().length !== 0) {
          this.selectedParent = this.gridApi.getSelectedNodes()[0].data;
        }
        else {
          this.selectedParent = this.$store.state.company ? this.$store.state.company : null;
        }
        this.departmentShow = true;
      } else if (typeof this.selected[0].type === 'undefined') {
        this.departmentId = this.selected[0].uuId;
        
        // for an edited department set the parent node data
        if (this.gridApi.getSelectedNodes().length !== 0) {
          this.selectedParent = this.gridApi.getSelectedNodes()[0].parent.data;
        }
        else {
          this.selectedParent = null;
        }
        this.departmentShow = true;
      } else { // a company
        this.companyId = this.selected[0].uuId;
        
        // for an edited department set the parent node data
        if (this.gridApi.getSelectedNodes().length !== 0) {
          this.selectedParent = this.gridApi.getSelectedNodes()[0].parent.data;
        }
        else {
          this.selectedParent = null;
        }
        this.companyShow = true;
      }
      this.resetAlert();
    }, 
    saveNodeState() {
      const self = this;
      this.nodeState = {};
      this.gridApi.forEachNode(node => {
        self.nodeState[node.id] = { expanded: node.expanded, selected: node.selected };
      });
    },
    restoreNodeState() {
      const self = this;
      if (this.nodeState) {
        this.gridApi.forEachNode(node => {
          if (node.id in self.nodeState) {
            node.setExpanded(self.nodeState[node.id].expanded);
            node.setSelected(self.nodeState[node.id].selected);
          }
        });
        this.nodeState = {};
      }
    },
    async reloadData(saveState = true) {

      if (this.lackOfMandatoryField()) {
        this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return
      } else {
        this.noRowsMessage = null
      }

      if (saveState) {
        this.saveNodeState();
      }
      this.restoreSelection = this.selected.length !== 0 ? [this.selected[0].uuId] : [];
      let companies = null;
      const { cmdList, departmentFields, companyFields } = prepareDepartmentTreeRequest(this, false);
       
      const responseData = await compositeService.exec(cmdList, true)
      .catch(e => {
        // console.error(error) // eslint-disable-line no-console
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_api') {
            this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'))
          }
        }
        return null;
      });

      const companyKeys = getKeysWithoutRedactedFields(companyFields, { data: responseData.data.feedbackList[0] });
      const departmentKeys = getKeysWithoutRedactedFields(departmentFields, { data: responseData.data.feedbackList[1] });
      companies = responseData.data.feedbackList[0].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[companyKeys[j]] = i[j];
        }
        return result;
      });
      let departments = responseData.data.feedbackList[1].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[departmentKeys[j]] = i[j];
        }
        return result;
      });
      let staffNoDep = [];

      if (companies == null) {
        this.rowData = [];
        this.gridOptions.api.setRowData(this.rowData);
        return;
      }
      
      const tree = makeTree(companies, departments, staffNoDep, false, true, this.company);
      this.treeData = pruneTree(this, tree, this.searchFilter);
      this.rowData = extractRowsFromData(this, '', this.treeData, false);
      
      setTimeout(() => {
        this.processNodes();
        this.restoreNodeState();
      }, 100);
    },
    modalSuccess(payload) {
      this.reloadData();
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('department.progress.deleting');
      const toDeleteIds = this.selected.map(i => { return { uuId: i.uuId } });
      const toDeleteIdNames = this.selected.map(i => { return { uuId: i.uuId, name: i.name } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`department.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];
      this.inProgressLabel = this.$t('department.progress.deleting');
      this.inProgressShow = true;
      
      await departmentService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('department.delete_partial');
          alertMsgDetailTitle = this.$t(`department.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`department.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response && e.response.status == 422) {
          alertMsgDetailTitle = this.$t(`department.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = e.response.data[e.response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      });

      this.inProgressLabel = null;
      this.inProgressShow = false;
      
      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);

      this.reloadData();
      this.selected = [];
      this.selectedParent = null;
      this.gridApi.deselectAll();
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    getCompany(node) {
      while (node) {
        if (typeof node.data.type !== 'undefined') {
          return { uuId: node.data.uuId, name: node.data.name, color: node.data.color };
        }
        node = node.parent;
      }
      return null;
    },
    ok(event) {
      const self = this;
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name, type: i.data.type, color: i.data.color, company: self.getCompany(i)} });
     
      // check that departments are from the same company
      const company = details[0].company;
      for (let i = 0; i < details.length; i++) {
        const dept = details[i];
        if (dept.company.uuId !== company.uuId) {
          this.resetAlert({ msg: this.$t('department.error.company'), alertState: alertStateEnum.ERROR });
          event.preventDefault();
          return false;
        }
      }
      
      if(this.preselected && details.length > 0) {
        details[0].oldId = this.preselected;
      }
      const ids = details.map(i => i.uuId);
      this.$emit('ok', { ids, details });
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    detailLinkId(params) {
      if (params.data && params.data.path) {
        return params.data.path;
      }
      return params.data.uuId;
    },
    openDetail(id) {
      const node = this.gridApi.getRowNode(id);
      this.selectedParent = node? node.parent.data : null;
      if (typeof node.data.type === 'undefined') {
        this.departmentId = node.data.uuId;
        this.departmentShow = true;
      }
      else {
        this.companyId = node.data.uuId;
        this.companyShow = true;
      }
      this.resetAlert();
    },
    applyFilter(pattern) {
      const filter = pattern.substr(5);
      if (filter === ".*.*") { // empty
        this.searchFilter = "";
      }
      else {
        this.searchFilter = pattern.substr(5);
      }
      this.reloadData();
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.selected[0].name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('department.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.processDuplicate();
        } else {
          this.duplicateInProgress = false;
        }
      });
    },
    async processDuplicate() {
      const selectedNode = this.gridApi.getRowNode(this.selected[0].path ? this.selected[0].path : this.selected[0].uuId);
      const data = { name: this.duplicateName };
      let parentToLink = null;
      if (selectedNode.parent.data) {
        if (typeof selectedNode.parent.data.type === 'undefined') {
          // The parent is a department so add it to the request
          data.parent = selectedNode.parent.data.uuId;
        }
        else {
          // the parent is a company so link it after adding
          parentToLink = selectedNode.parent.data.uuId;
        }
      }
      
      const uuId = await departmentService.clone(this.selected[0].uuId, data)
      .then((response) => {
        return response.data.jobClue.uuId;
      })
      .catch(e => {
        let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.DEPARTMENT')]);
        if(e.response && e.response.data && e.response.data.jobClue != null) {
          const clue = e.response.data.jobClue.clue;
          if ('Unknown_holder' === clue) {
            alertMsg = this.$t('department.error.duplicate_not_found');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        } else {
          this.httpAjaxError(e);
        }
        return null;
      });

      if (uuId == null) {
        this.$nextTick(() => {
          this.duplicateInProgress = false;
        });
        this.duplicateShow = false;
        return; //Stop proceeding further when uuId is null. Failed to clone.
      }
      
      if (parentToLink) {
        const parentData = {};
        parentData['uuId'] = parentToLink;
        parentData['departmentList'] = [{'uuId': uuId}];
        await companyService.link([parentData], 'department')
        .then(() => {
          return true;
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return false;
        });
      }
                
      this.resetAlert({ msg: this.$t('department.duplicate') });
      this.reloadData();
      
      this.duplicateShow = false;
      this.errors.clear();
      //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
      this.$nextTick(() => {
        this.duplicateInProgress = false;
      });
    },
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.department_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.department_selector_list);
            self.coloring.none = self.settings.department_selector_coloring ? self.settings.department_selector_coloring.none : false;
            self.coloring.company = self.settings.department_selector_coloring ? self.settings.department_selector_coloring.company : false;
            self.coloring.location = self.settings.department_selector_coloring ? self.settings.department_selector_coloring.location : false;
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == 'ag-Grid-AutoColumn');
      if (autoGroupSetting) {
        data.autoGroupColumnDef.width = autoGroupSetting.width;
        data.autoGroupColumnDef.sort = autoGroupSetting.sort;
        if (data.gridOptions.api != null) {
          data.gridOptions.api.setAutoGroupColumnDef({
            ...data.autoGroupColumnDef
          })
        }
      }

      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(self) {
      return function(params) {
        if (params.column.colId.indexOf('ag-Grid-AutoColumn') !== -1) {
          return params.node.data.name;
        }
        else if (params.column.colId.indexOf('path') !== -1) {
          const ids = params.node.data.path.split(', ');
          let path = '';
          let parentPath = '';
          for (const id of ids) {
            const parent = self.gridApi.getRowNode(parentPath);
            if (parent) {
              path = path !== '' ? `${path}/${parent.data.name}` : parent.data.name;
            }
            parentPath = parentPath === '' ? id : `${parentPath}, ${id}`
          }
          return path;
        }
        return params.value;
      }
    },
    fileExport() {
     
      const self = this;
      this.gridApi.exportDataAsExcel({ 
        fileName: 'Departments'
        , sheetName: 'Departments'
        , allColumns: true
        , rowHeight: 20
        , processCellCallback: self.processCellCallback(self)
      });
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addDepartments(items, null);
      this.inProgressShow = false;
      this.reloadData();
    },
    async addDepartments(items, parent) {
      this.inProgressLabel = this.$t('department.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        const data = {
          name: item.name,
          parent: parent,
          identifier: item.identifier,
          color: item.color
        }
        
        const result = await departmentService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result) {
                      
          if (result &&
            item.tag) {
            await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), departmentLinkTagService);
          }
          
          if (item.items) {
            await this.addDepartments(item.items, result);   
          }
        }        
        percentage++;
        this.inProgressLabel = this.$t('department.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['department_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data, params) {
      const companyColor = data &&
        data.companyColor &&
        data.companyColor.length > 0 &&
        data.companyColor[0] !== '' ?
        data.companyColor[0] :
        this.getParentCompanyColor(params);
        
      if (data &&
        data.color &&
        this.coloring.department) {
        return data.color;
      }
      else if (companyColor &&
        this.coloring.company) {
        return companyColor;
      }
    },
    getParentCompanyColor(params) {
      if (params.data &&
          params.data.companyColor &&
          params.data.companyColor.length > 0 &&
          params.data.companyColor[0] !== '') {
        return params.data.companyColor[0];   
      }
      else if (params.node && params.node.parent) {
        return this.getParentCompanyColor(params.node.parent);
      }
      return null;
    },
    lackOfMandatoryField() {
      //Permission check on mandatory properties
      const departmentMandatoryFields = ['uuId', 'name']
      const departmentViewDenyProperties = getPermissionDenyProperties('DEPARTMENT', 'VIEW')
      let showNoData = false
      for (const field of departmentMandatoryFields) {
        if (departmentViewDenyProperties.includes(field)) {
          showNoData = true
        }
      }
      if (showNoData) {
        return true
      }

      const companyMandatoryFields = ['uuId', 'name', 'type']
      const companyViewDenyProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
      for (const field of companyMandatoryFields) {
        if (companyViewDenyProperties.includes(field)) {
          showNoData = true
        }
      }
      return showNoData
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('department.grid.no_data');
    },
    showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          this.gridOptions.api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>

<style lang="scss" scoped>
  .grid-toolbar {
    .btn.btn-secondary {
      background-color: transparent;
      border-color: transparent;
      padding: 2px 6px;
      margin: 8px 3px;
      border-radius: 3.2px;
      color: var(--grid-toolbar-button);

      &:focus {
        box-shadow: none;
      }
      &:first-child {
        margin-left: 8px;
      }
    }
  }

  .department-grid-height {
    height: calc(100vh - 380px);
    min-height: 250px;
  }

</style>